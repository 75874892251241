// authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authInfo: {
    access_token: null
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState, // Use the initialState defined above
  reducers: {
    setAuthInfo: (state, action) => {
      state.authInfo = action.payload;
    },
  },
});

export const { setAuthInfo } = authSlice.actions;
export default authSlice.reducer;
