
import moment from 'moment';
export const date = () => {
    let numbers = [];
    for (let i = 0; i < 31; i++) {
      numbers.push(i+1);
    }
    return numbers;
}

export const month = () => {
    let numbers = [];
    for (let i = 0; i < 12; i++) {
      numbers.push(i+1);
    }
    return numbers;
}

export const year = () => {
    let numbers = [];
    for (let i = 2024; i >= 1920; i--) {
      numbers.push(i+1);
    }
    return numbers;
}
export const formatBirthdate = (date) => {
  const parsedDate = moment(date, 'YYYYY-MM-DD');    
  const formattedDate = parsedDate.format('D-M-YYYY');
  return formattedDate
}
export const formatExpired = (date) => {
  const parsedDate = moment(date, 'YYYY-MM-DD');    
  const formattedDate = parsedDate.format('YYYY/MM/DD');
  return formattedDate
}
export const isValidEAN13 = (data) => {
  if (data.length !== 13 || !/^\d{13}$/.test(data)) {
    return false;
  }
  // Validate checksum digit
  const digits = data.split('').map(Number);
  const checkDigit = digits.pop(); // Last digit is checksum
  const sum = digits.reduce((acc, digit, index) => {
    return acc + (index % 2 === 0 ? digit * 1 : digit * 3);
  }, 0);
  const calculatedCheckDigit = (10 - (sum % 10)) % 10;
  return calculatedCheckDigit === checkDigit;
};