import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import EN from "assets/locales/en/translation.json";
import JP from "assets/locales/jp/translation.json";

const resources = {
  en: {
    translation: EN,
  },
  jp: {
    translation: JP,
  },
};

let selectedLanguage = navigator.language === "ja_JP" || navigator.language === "jp" ? "jp" : navigator.language === "en" ? "en" : "en";
export const checkLanguage = () => {
  const lang = localStorage.getItem("lang");
  if (!lang) {
    localStorage.setItem("lang", selectedLanguage);
  } else {
    const checkLang = Object.keys(resources).find((item) => {
      if (item === lang) {
        return item;
      }
    });
    if (checkLang) selectedLanguage = lang;
    else localStorage.setItem("lang", selectedLanguage);
  }
  return selectedLanguage;
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: false,
    lng: checkLanguage(),
    detection: {
      order: ["queryString", "cookie"],
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
