/* eslint-disable react/jsx-no-comment-textnodes */
import { useCallback, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { GET_USER_INFO } from "services/users";
import { BeatLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setError } from "store/user/errorSlice";
import logo from "assets/images/logo.png";
import "./style.css";
import useHandleError from "hooks/useHandleError";

const Layout = (props) => {
  const {
    children,
    isShowLoading = true,
    title,
    showPanel = true,
    isPageNotFound = false,
    onChangeRoute = () => {},
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [language, setlanguage] = useState("ja");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleError = useHandleError();

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    setlanguage(lang);
  }, [localStorage.getItem("lang")]);

  const onGetDetail = useCallback(async () => {
    try {
      setIsLoading(true);

      const isMembership = location?.pathname?.includes("membership");
      const isTerm = location?.pathname?.includes("term-of-use");
      const res = await GET_USER_INFO();
      const data = res?.data;
      if (!isTerm) {
        if (data.isCardLinked) {
          navigate("/membership");
          onChangeRoute("/membership");
        } else if (data.isLocked) {
          dispatch(
            setError({
              error: "locked",
            })
          );
          navigate("/error");
          onChangeRoute("/error");
        } else if (isMembership && !data.isCardLinked) {
          navigate("/");
          onChangeRoute("/");
        }
      }
      onChangeRoute("/");
    } catch (error) {
      handleError(error);
      if (typeof error === "object") {
        console.error("get user auth", JSON.stringify(error));
      } else {
        console.error("get user auth", error);
      }
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!isPageNotFound) {
      onGetDetail();
    }
  }, [onGetDetail]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the location changes
  }, [location]);

  return (
    <div
      className={`${
        loading
          ? "absolute inset-0 cursor-not-allowed pointer-events-none"
          : "font-primary"
      }`}
    >
      {(isLoading || loading) && isShowLoading && (
        <span className="!flex loader">
          <BeatLoader size={20} color={"#404040"} />
        </span>
      )}
      <div
        className={`${
          loading || isLoading
            ? "opacity-20 cursor-not-allowed pointer-events-none"
            : ""
        } w-full lg:max-w-mobile m-auto bg-white overflow-hidden gap-30 font-primary min-h-screen`}
      >
        <div
          className={`flex flex-col items-center pb-8 font-semibold bg-whitew-full lg:max-w-mobile min-h-screen ${
            showPanel ? "pt-8" : "pt-0"
          } body-wrapper relative`}
        >
          {showPanel && (
            <div className="flex justify-center">
              <img
                src={logo}
                alt=""
                className={"absolute left-4 w-[55px] top-5 cursor-pointer"}
              />
              <p
                className={`ryogothic text-primary-100 ${
                  title ? "panel-title z-10" : "text-2lg"
                } font-semibold`}
              >
                {title ? title : "和光カード連携画面"}
              </p>
            </div>
          )}
          {children}
        </div>
      </div>
      <Toaster
        toastOptions={{
          className: "toaster",
        }}
        position="top-center"
        reverseOrder={false}
      />
    </div>
  );
};

export default Layout;
