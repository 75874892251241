import { React } from "react";
import Layout from "./Layout";
import { useNavigate } from "react-router-dom";
const TermUse = () => {
  const navigate = useNavigate();

  return (
    <Layout isShowButtonHome={false} title="WAKO Membership Program">
      <div className="grid gap-4 w-full pl-10">
        <div className="ryogothic pt-16 text-base font-semibold text-primary-100  ">
          <span>利用規約について</span>
        </div>
        <div className="ryogothic text-xs font-light text-primary-100 grid">
          <span>本サービスの利用に起因する利用者および提供者の</span>
          <span>権利および義務は、本利用規約に準拠します。</span>
        </div>
        <div className="ryogothic text-xs font-light text-primary-100 grid">
          <span>1. 定義</span>
          <div className="px-4 grid">
            <span>1.1. 利用規約:</span>
            <span>契約第 2358 条以降の趣旨で、同意を得て利用者と</span>
            <span>提供者との間で締結された民法のライセンス契約民法</span>
            <span>
              改正された民法の法令 No. <u>89/2012</u> の項目
            </span>
          </div>
        </div>
      </div>

      <div className="pt-40 pb-4 w-full grid gap-1 justify-center">
        <button
          className={`button bg-black w-[152px]`}
          onClick={() => navigate("/membership")}
        >
          ＜ 戻る
        </button>
      </div>
    </Layout>
  );
};

export default TermUse;
