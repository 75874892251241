// userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: {
    first_name: null,
    last_name: null,
    first_name_kana: null,
    last_name_kana: null,
    birthdate: null,
    user_id: null,
    card_number: null,
    point_balance: 0,
    point_expiration: null,
    birthdate_exist: false, 
    last_name_exist: false, 
  },
};


const userSlice = createSlice({
  name: "user",
  initialState, // Use the initialState defined above
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
});

export const { setUserInfo } = userSlice.actions;
export default userSlice.reducer;
