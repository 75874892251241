/* eslint-disable react/jsx-no-comment-textnodes */
import { useEffect, useState } from "react";
import Layout from "./Layout";

const NotFound = () => {
  const [error, setError] = useState("");

  useEffect(() => {
    console.error("Page 404 not found");
  }, []);

  const handleBackLiff = () => {
    const lineAppUrl = `http://line.me/ti/p/${process.env.REACT_APP_LINE_BOT_ID}`;
    window.location.href = lineAppUrl;
  };

  return (
    <Layout isShowLoading={false} isPageNotFound={true}>
      <div className="p-6 pt-28 pb-12 w-full grid gap-1">
        <div
          className="ryogothic text-base text-center font-light leading-8 text-error-100 w-full"
          dangerouslySetInnerHTML={{
            __html: "ページが見つかりませんでした。",
          }}
        ></div>
      </div>
      <div className="pt-12 px-8 w-full grid gap-1">
        <button
          className={`button ${
            error === "locked" ? "bg-black" : "bg-[#4883F6]"
          }`}
          onClick={handleBackLiff}
        >
          閉じる
        </button>
      </div>
    </Layout>
  );
};

export default NotFound;
