import {
  ACTION_DELETE,
  ACTION_GET,
  ACTION_PATCH,
  ACTION_POST,
  ACTION_PUT,
  BASE_URL,
} from "./config";

export const GET_VERIFY_CARD = (params) => {
  const url = `${BASE_URL}/card/verify?card_number=${params}`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_USER_INFO = () => {
  const url = `${BASE_URL}/user/auth`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_MEMBERSHIP_INFO = () => {
  const url = `${BASE_URL}/user`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_QRCODE = () => {
  const url = `${BASE_URL}/user/generate-qr-code`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const UPDATE_USER_INFO = (params) => {
  const url = `${BASE_URL}/card/link`;
  return new Promise((resolve, reject) => {
    ACTION_POST(url, params)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const DELETE_USER_INFO = () => {
  const url = `${BASE_URL}/card/unlink`;
  return new Promise((resolve, reject) => {
    ACTION_POST(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
